import { useCallback } from 'react';
import { useApiCall } from '@jarvis/react-portal-addons';
import { AccountMgtSvcClient } from '@jarvis/web-stratus-client';

export default function useActivateHpPlusApiCall({
  authProvider,
  stack,
}) {
  const apiCall = useCallback(async ({ accountId, cloudId }) => {
    const accountMgtSvcClient = new AccountMgtSvcClient(stack, authProvider);
    const response = await accountMgtSvcClient.updateDeviceBizModel(accountId, cloudId, 'E2E');
    return response.data;
  }, [authProvider, stack]);

  return useApiCall({ apiCall, init: false });
}
