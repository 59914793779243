import React from 'react';
import PropTypes from 'prop-types';
import { PreferencesProvider, RootProvider } from '@jarvis/react-portal-addons';
import App from '../src';
import pkg from '../package.json';
import DEFAULT_PREFERENCES from '../src/constants/defaultPreferences';
import resources from '../src/assets/locale';

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root(props) {
  const { properties, stack } = props;
  const { v1 } = window.Shell;

  return (
    <section
      id={pkg.name}
      style={{
        height: '100%',
        minHeight: 'calc(100vh - 176px)',
      }}
    >
      <RootProvider
        localization={v1.localization}
        resources={resources}
        shell={v1}
        authProvider={v1.authProvider}
        stack={stack}
        featureFlags={v1.featureFlags}
        navigation={v1.navigation}
      >
        <PreferencesProvider currentPreferences={properties} defaultPreferences={DEFAULT_PREFERENCES}>
          <App {...props} />
        </PreferencesProvider>
      </RootProvider>
    </section>
  );
}

Root.propTypes = {
  properties: PropTypes.shape.isRequired,
  stack: PropTypes.number.isRequired,
};
