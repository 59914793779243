import React, { useCallback } from 'react';
import { useApiCall, useRootContext } from '@jarvis/react-portal-addons';
import { JarvisWebHttpClient } from '@jarvis/web-http';
import { AccountMgtSvcClient, DeviceCacheApiClient } from '@jarvis/web-stratus-client';
import { getAgenaUrl } from '../utils/utils';
import { useDeviceApiAsDeviceCache } from './useDeviceApiAsDeviceCache';

export default function useGetInstantInkMonthsApiCall({
  authProvider,
  stack,
}) {
  const { featureFlags } = useRootContext();
  const hpPlusSecondOfferIIMonthsMocked = featureFlags?.useReactFeatureFlag?.(
    React,
    'ucde-portal',
    {
      key: 'hpPlusSecondOfferIIMonthsMocked',
      defaultValue: false,
    },
  );
  const useDeviceCache = featureFlags?.useReactFeatureFlag?.(
    React,
    'ucde-portal',
    {
      key: 'useDeviceCache',
      defaultValue: false,
    },
  );
  const hpPlusSecondOfferIIMonthsGBtoUK = featureFlags?.useReactFeatureFlag?.(
    React,
    'ucde-portal',
    {
      key: 'hpPlusSecondOfferIIMonthsGBtoUK',
      defaultValue: false,
    },
  );
  const { getDevice } = useDeviceApiAsDeviceCache({ authProvider, stack });

  const apiCall = useCallback(async deviceId => {
    if (hpPlusSecondOfferIIMonthsMocked) {
      return 6;
    }

    const accountMgtSvcClient = new AccountMgtSvcClient(stack, authProvider);
    const accountResponse = await accountMgtSvcClient.getAccount();
    const regionId = accountResponse?.data?.regionId;
    let country = regionId?.toUpperCase();
    if (hpPlusSecondOfferIIMonthsGBtoUK && country === 'GB') {
      country = 'UK';
    }

    const deviceCacheApiClient = new DeviceCacheApiClient(stack, authProvider);
    let deviceResponse = null;
    if (useDeviceCache) {
      deviceResponse = await deviceCacheApiClient.show(deviceId);
    } else {
      deviceResponse = await getDevice(deviceId);
    }

    const modelNumber = deviceResponse?.data?.identity?.makeAndModel?.number;
    const serialNumber = deviceResponse?.data?.identity?.serialNumber;
    const flow = 'oobe';
    const bizModel = 'e2e';

    const agenaUrl = getAgenaUrl(stack);
    const client = new JarvisWebHttpClient({
      defaultBaseURL: agenaUrl,
      defaultAuthProvider: authProvider,
    });
    const options = {
      url: `/printer_registrations/${country}/${modelNumber}/${serialNumber}/incentive_eligibility?flow=${flow}&bizModel=${bizModel}`,
      headers: { Accept: 'application/vnd.agena+json;version=3' },
    };
    try {
      const response = await client.get(options);
      return response.data?.plans?.[0]?.oobe_incentive_free_months || 0;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        if (data?.error?.code === '404') {
          return 0;
        }
      }
      throw error;
    }
  }, [
    authProvider,
    getDevice,
    hpPlusSecondOfferIIMonthsGBtoUK,
    hpPlusSecondOfferIIMonthsMocked,
    stack,
    useDeviceCache,
  ]);

  return useApiCall({ apiCall, init: false });
}
