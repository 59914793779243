import styled from 'styled-components';
import Tokens from '@veneer/tokens';

export const Link = styled.a`
  && {
    color: ${Tokens.colorHpBlue6};
    text-decoration: none;
    display: inline-block;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;

    :hover {
      color: ${Tokens.colorHpBlue7};
      text-decoration: underline;
    }
    :active {
      color: ${Tokens.colorHpBlue8};
      text-decoration: underline;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
`;

export const SuperscriptLink = styled(Link)`
  && {
    font-size: 60%;
    font-weight: bold;
    position: relative;
    vertical-align: baseline;
    top: -0.5em;
  }
`;

export default Link;
