import React, { useCallback } from 'react';
import { useApiCall, useRootContext } from '@jarvis/react-portal-addons';
import { DeviceCacheApiClient, SessionMgtSvcClient } from '@jarvis/web-stratus-client';
import { useDeviceApiAsDeviceCache } from './useDeviceApiAsDeviceCache';

export default function useCreateInstantInkSessionApiCall({
  authProvider,
  stack,
}) {
  const { featureFlags } = useRootContext();
  const useDeviceCache = featureFlags?.useReactFeatureFlag?.(
    React,
    'ucde-portal',
    {
      key: 'useDeviceCache',
      defaultValue: false,
    },
  );
  const { getDevice } = useDeviceApiAsDeviceCache({ authProvider, stack });

  const apiCall = useCallback(async ({ cloudId }) => {
    let deviceResponse = null;
    if (useDeviceCache) {
      const deviceCacheApiClient = new DeviceCacheApiClient(stack, authProvider);
      deviceResponse = await deviceCacheApiClient.show(cloudId);
    } else {
      deviceResponse = await getDevice(cloudId);
    }

    const modelNumber = deviceResponse?.data?.identity?.makeAndModel?.number;
    const serialNumber = deviceResponse?.data?.identity?.serialNumber;
    // this logic is based on code for endpoint /sessions/instantink from ucde-gateway
    // source: ucde-gateway\src\com\hp\visionapi\service\stratus\SessionServiceImp.kt
    const sessionMgtSvcClient = new SessionMgtSvcClient(stack, authProvider);
    const token = window.Shell.v1.authProvider.getDecodedUserStratusJWTPayload();
    const userId = token?.client_id;
    const tenantId = token?.fq_tenant_id;
    // check if the values should be userId = token.stratus_id and tenantId = token.tenant_id
    const sessionInfo = {
      resourceId: null,
      payload: JSON.stringify({
        uiInfo: {
          callType: 'P2',
          callValue: 'YETI',
        },
        userInfo: {
          userId,
          tenantId,
        },
        deviceInfo: {
          serialNumber,
          modelNumber,
          bizModel: 'E2E',
        },
      }),
      timeout: 600,
    };
    const response = await sessionMgtSvcClient.createSession(sessionInfo);
    // response data should contain the properties:
    // resourceId, state, payload, timeout, expireAt
    return response.data;
  }, [authProvider, getDevice, stack, useDeviceCache]);

  return useApiCall({ apiCall, init: false });
}
