const hpPlus = require('./hp-plus.svg');
const hpPlusBlue = require('./hp-plus-blue.svg');
const orangeTriangle = require('./orange-triangle.svg');
const genericPrinter = require('./generic-printer.svg');
const info = require('./info.svg');
const error = require('./error.svg');
const checkmark = require('./checkmark.svg');
const checkmarkOrange = require('./checkmark-orange.svg');
const x = require('./x.svg');

const tileMakePrinterSmarter = require('./tile-make-printer-smarter.png');
const tileFromAnywhere = require('./tile-from-anywhere.png');
const tileForest = require('./tile-forest.png');
const tileGetMoreDone = require('./tile-get-more-done.png');
const tileGetInk = require('./tile-get-ink.png');

const checkOffer = require('./check-offer.svg');
const delivery = require('./delivery.svg');
const adv = require('./adv.svg');

const Images = {
  hpPlus,
  orangeTriangle,
  genericPrinter,
  info,
  checkmark,
  checkmarkOrange,
  x,
  tileMakePrinterSmarter,
  tileForest,
  tileGetMoreDone,
  tileGetInk,
  tileFromAnywhere,
  checkOffer,
  delivery,
  adv,
  hpPlusBlue,
  error,
};

export default Images;
