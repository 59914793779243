import React from 'react';
import PropTypes from 'prop-types';
import { useI18n, useRootContext } from '@jarvis/react-portal-addons';
import Images from '../../assets/images';
import { COUNTRIES } from '../../utils/constants';
import {
  CardContainer,
  CardContent,
  Img,
  MainContainer,
  StyledLink,
  Text1,
  Text2,
  Text3,
} from './styles';

const ExclusiveOffers = ({
  isPrivate,
  instantInkMonths,
  noInstantInk,
}) => {
  const { t } = useI18n();
  const { localization } = useRootContext();
  const country = localization.country.toLowerCase();

  const getTextBodyCardHpPlus = () => {
    if (country === COUNTRIES.AUS || country === COUNTRIES.NZ) {
      return t('home.exclusiveOffers.bodyCard1_AUS_NZ', {
        tagPlaceHolderStart: '<span>',
        tagPlaceHolderEnd: '</span>',
        interpolation: { escapeValue: false },
      });
    }

    return t('home.exclusiveOffers.bodyCard1_US_EMEA', {
      tagPlaceHolderStart: '<span>',
      tagPlaceHolderEnd: '</span>',
      interpolation: { escapeValue: false },
    });
  };

  const getTextBodyCardDelivery = () => {
    if (!isPrivate) {
      return t('home.exclusiveOffers.bodyCard3Generic');
    }

    if (country === COUNTRIES.US) {
      return t((instantInkMonths > 1)
        ? 'home.exclusiveOffers.bodyCard3_US'
        : 'home.exclusiveOffers.bodyCard3_US1Month',
      {
        tagPlaceHolderStart: '<span>',
        tagPlaceHolderEnd: '</span>',
        months: instantInkMonths,
        interpolation: { escapeValue: false },
      });
    }

    return t((instantInkMonths > 1)
      ? 'home.exclusiveOffers.bodyCard3_AUS_NZ_EMEA'
      : 'home.exclusiveOffers.bodyCard3_AUS_NZ_EMEA1Month',
    {
      tagPlaceHolderStart: '<span>',
      tagPlaceHolderEnd: '</span>',
      months: instantInkMonths,
      interpolation: { escapeValue: false },
    });
  };

  const hpPlusCard = () => {
    const footnote = noInstantInk ? 4 : 7;

    return (
      <CardContent>
        <Img alt="HP+" src={Images.checkOffer} />
        <Text3>
          <Text2>
            {getTextBodyCardHpPlus()}
          </Text2>
          &nbsp;
          <StyledLink href={`#footnote${footnote}`}>
            {footnote}
          </StyledLink>
        </Text3>
      </CardContent>
    );
  };

  const deliveryCard = () => {
    if (noInstantInk) {
      return <></>;
    }

    return (
      <CardContent>
        <Img alt="Delivery" src={Images.delivery} />
        <Text3>
          <Text2>
            {getTextBodyCardDelivery()}
          </Text2>
          &nbsp;
          <StyledLink href="#footnote4">
            4
          </StyledLink>
        </Text3>
      </CardContent>
    );
  };

  const advCard = () => {
    const footnotes = noInstantInk ? ['1', '2', '6'] : ['1', '5', '9'];
    return (
      <CardContent>
        <Img alt="ADV" src={Images.adv} />
        <Text3>
          <Text2>
            {t('home.exclusiveOffers.bodyCard2', {
              tagPlaceHolderStart: '<span>',
              tagPlaceHolderEnd: '</span>',
              interpolation: { escapeValue: false },
            })}
          </Text2>

          {(footnotes.map(footnote => (
            <StyledLink key={footnote} href={`#footnote${footnote}`}>
              &nbsp;
              {footnote}
            </StyledLink>
          )))}
        </Text3>
      </CardContent>
    );
  };

  return (
    <MainContainer>
      <Text1>{t('home.exclusiveOffers.header')}</Text1>
      <CardContainer>
        {hpPlusCard()}
        {deliveryCard()}
        {advCard()}
      </CardContainer>
    </MainContainer>
  );
};

ExclusiveOffers.propTypes = {
  isPrivate: PropTypes.bool.isRequired,
  instantInkMonths: PropTypes.number.isRequired,
  noInstantInk: PropTypes.bool.isRequired,
};

export default ExclusiveOffers;
