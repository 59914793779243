import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Container from './styles';
import Tile from './Tile';
import Images from '../../assets/images';

const OfferTiles = ({ isPrivate, instantInkMonths, noInstantInk }) => {
  const [tiles, setTiles] = useState([]);
  const allTiles = {
    printerSmart: {
      name: 'printerSmart',
      image: Images.tileMakePrinterSmarter,
      titleKey: 'home.benefitCards.printerSmart.header',
      bodyKey: ['home.benefitCards.printerSmart.bodyCopy'],
      footnoteNumbers: ['1', '5'],
    },
    printerSmartNoII: {
      name: 'printerSmartNoII',
      image: Images.tileMakePrinterSmarter,
      titleKey: 'home.benefitCards.printerSmart.header',
      bodyKey: ['home.benefitCards.printerSmart.bodyCopyNonInstantInk'],
      footnoteNumbers: ['1'],
    },
    printFromAnywhere: {
      name: 'printFromAnywhere',
      image: Images.tileFromAnywhere,
      titleKey: 'home.benefitCards.printFromAnywhere.header',
      bodyKey: ['home.benefitCards.printFromAnywhere.bodyCopy'],
      footnoteNumbers: ['2'],
    },
    getLinkPublic: {
      name: 'getLinkPublic',
      image: Images.tileGetInk,
      titleKey: 'home.benefitCards.getLink.header',
      bodyKey: ['home.benefitCards.getLink.bodyCopyGeneric', {
        tagPlaceHolder: '<strong>',
        tagPlaceHolderEnd: '</strong>',
        months: instantInkMonths,
        interpolation: { escapeValue: false },
      }],
      footnoteNumbers: ['2', '3', '4'],
    },
    getLinkPrivate: {
      name: 'getLinkPrivate',
      image: Images.tileGetInk,
      titleKey: 'home.benefitCards.getLink.header',
      bodyKey: [`home.benefitCards.getLink.${instantInkMonths > 1 ? 'bodyCopy1Month' : 'bodyCopy'}`, {
        tagPlaceHolder: '<strong>',
        tagPlaceHolderEnd: '</strong>',
        months: instantInkMonths,
        interpolation: { escapeValue: false },
      }],
      footnoteNumbers: ['2', '3', '4'],
    },
    getMore: {
      name: 'getMore',
      image: Images.tileGetMoreDone,
      titleKey: 'home.benefitCards.getMore.header',
      bodyKey: ['home.benefitCards.getMore.bodyCopy'],
      footnoteNumbers: noInstantInk ? ['2'] : ['5'],
    },
    protectForest: {
      name: 'protectForest',
      image: Images.tileForest,
      titleKey: 'home.benefitCards.protectForest.header',
      bodyKey: ['home.benefitCards.protectForest.bodyCopy'],
      footnoteNumbers: noInstantInk ? ['3', '5'] : ['6', '8'],
    },
  };

  useEffect(() => {
    if (isPrivate && !noInstantInk) {
      setTiles([
        allTiles.printerSmart,
        allTiles.getLinkPrivate,
        allTiles.getMore,
        allTiles.protectForest,
      ]);
    }

    if (isPrivate && noInstantInk) {
      setTiles([
        allTiles.printerSmartNoII,
        allTiles.printFromAnywhere,
        allTiles.getMore,
        allTiles.protectForest,
      ]);
    }

    if (!isPrivate) {
      setTiles([
        allTiles.printerSmart,
        allTiles.getLinkPublic,
        allTiles.getMore,
        allTiles.protectForest,
      ]);
    }
  }, [noInstantInk]);

  return (
    <Container>
      {tiles.map(tile => (
        <Tile
          key={tile.name}
          image={tile.image}
          titleKey={tile.titleKey}
          bodyKey={tile.bodyKey}
          footnoteNumbers={tile.footnoteNumbers}
        />
      ))}
    </Container>
  );
};

OfferTiles.propTypes = {
  isPrivate: PropTypes.bool.isRequired,
  instantInkMonths: PropTypes.number.isRequired,
  noInstantInk: PropTypes.bool.isRequired,
};

export default OfferTiles;
