import styled from 'styled-components';

export const CoachContainer = styled.span`
  && {
    margin: 0;
    z-index: 1;

    svg {
      cursor: pointer;
      margin: 0;
      padding: 0;
    }
  }

  justify-self: center;
  align-self: center;

`;

export default CoachContainer;
