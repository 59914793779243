import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRootContext } from '@jarvis/react-portal-addons';
import Header from '../../components/Header';
import OfferTiles from '../../components/OfferTiles';
import Legal from '../../components/Legal';
import TrialAndMore from '../../components/TrialAndMore';
import ExclusiveOffers from '../../components/ExclusiveOffers';
import GetMore from '../../components/GetMore';
import { ScreenDisplayedEvent, publishEvent } from '../../utils/analytics';
import { getParameterQuery } from '../../utils/globals';
import Loader from '../../components/Loader';
import useGetInstantInkMonthsApiCall from '../../hooks/useGetInstantInkMonthsApiCall';

const Home = ({ data }) => {
  const [noInstantInk, setNoInstantInk] = useState(false);
  const [instantInkMonths, setInstantInkMonths] = useState(0);

  const { authProvider, stack } = useRootContext();

  const getInstantInkMonthsApiCall = useGetInstantInkMonthsApiCall({ authProvider, stack });
  const isPrivate = !!(data && data.printerName);

  useEffect(() => {
    publishEvent(ScreenDisplayedEvent);
  }, []);

  useEffect(() => {
    let months = 0;
    if (isPrivate
      && !getInstantInkMonthsApiCall.pending
      && !getInstantInkMonthsApiCall.success
      && !getInstantInkMonthsApiCall.error
    ) {
      const cloudId = getParameterQuery('cloudId');
      getInstantInkMonthsApiCall.makeApiCall(cloudId);
    }
    if (getInstantInkMonthsApiCall.success
      && getInstantInkMonthsApiCall.data
    ) {
      months = getInstantInkMonthsApiCall.data;
    }
    setInstantInkMonths(months);
    setNoInstantInk(months === 0);
  }, [
    data,
    getInstantInkMonthsApiCall,
    getInstantInkMonthsApiCall.data,
    getInstantInkMonthsApiCall.success,
    isPrivate,
  ]);

  if (getInstantInkMonthsApiCall.pending) {
    return (
      <Loader />
    );
  }

  return (
    <>
      <Header
        data={data || {}}
        isPrivate={isPrivate}
        instantInkMonths={instantInkMonths}
      />
      <OfferTiles
        isPrivate={isPrivate}
        instantInkMonths={instantInkMonths}
        noInstantInk={noInstantInk}
      />
      <ExclusiveOffers
        isPrivate={isPrivate}
        instantInkMonths={instantInkMonths}
        noInstantInk={noInstantInk}
      />
      <TrialAndMore
        isPrivate={isPrivate}
        instantInkMonths={instantInkMonths}
        noInstantInk={noInstantInk}
        data={data || {}}
      />
      <GetMore
        data={data || {}}
        isPrivate={isPrivate}
        instantInkMonths={instantInkMonths}
      />
      <Legal
        isPrivate={isPrivate}
        noInstantInk={noInstantInk}
        instantInkMonths={instantInkMonths}
      />
    </>
  );
};

Home.propTypes = {
  data: PropTypes.shape({
    country: PropTypes.string.isRequired,
    deviceEligibility: PropTypes.shape({
      eligible: PropTypes.bool.isRequired,
      expires: PropTypes.number.isRequired,
    }),
    deviceSKU: PropTypes.string.isRequired,
    encryptedClientID: PropTypes.string.isRequired,
    encryptedDeviceID: PropTypes.string.isRequired,
    printerImages: PropTypes.arrayOf(PropTypes.string),
    printerName: PropTypes.string,
  }).isRequired,
};

export default Home;
