import React from 'react';
import PropTypes from 'prop-types';
import { useI18n } from '@jarvis/react-portal-addons';
import ActivateOfferModal from '../ActivateOfferModal';
import Images from '../../assets/images';

import {
  Container,
  PrinterImage,
  RightContainer,
  Text1,
  Text2,
} from './styles';
import { epochToDate } from '../shared-components/DateTime';

const Printer = ({
  data,
  instantInkMonths,
}) => {
  const { t } = useI18n();
  const eligible = data && data.deviceEligibility && data.deviceEligibility.eligible;
  const timestamp = data && data.deviceEligibility && data.deviceEligibility.expires;
  const eligibleText = t('home.header.signedInUser.bodyCopy1', { printer: data.printerName });
  const nonEligibleText = t('home.header.signedInUser.bodyCopy1Option2', { printer: data.printerName });

  let imageSource = Images.genericPrinter;
  if (data.printerImages && data.printerImages.length > 0) {
    imageSource = data.printerImages[data.printerImages.length - 1];
  }

  return (
    <Container>
      <PrinterImage><img alt={data.printerName} src={imageSource} /></PrinterImage>
      <RightContainer>
        <Text1>
          {
            eligible ? eligibleText : nonEligibleText
          }
        </Text1>
        {
          eligible && (
            <Text2>
              {t('home.getMore.bodyCopySignedIn', {
                dateExpiration: epochToDate(timestamp),
                interpolation: { escapeValue: false },
              })}
            </Text2>
          )
        }
        <ActivateOfferModal
          data={data}
          eligible={eligible}
          instantInkMonths={instantInkMonths}
          source="printer-card"
        />
      </RightContainer>
    </Container>
  );
};

Printer.propTypes = {
  data: PropTypes.shape({
    country: PropTypes.string.isRequired,
    deviceEligibility: PropTypes.shape({
      eligible: PropTypes.bool.isRequired,
      expires: PropTypes.number.isRequired,
    }),
    deviceSKU: PropTypes.string.isRequired,
    encryptedClientID: PropTypes.string.isRequired,
    encryptedDeviceID: PropTypes.string.isRequired,
    printerImages: PropTypes.arrayOf(PropTypes.string),
    printerName: PropTypes.string,
  }).isRequired,
  instantInkMonths: PropTypes.number.isRequired,
};

export default Printer;
