import styled from 'styled-components';
import Button from '@veneer/core/dist/scripts/button';

export const Container = styled.div`
  display: grid;
  flex-direction: column;
  padding: 90px 0 0 100px;
  background-color: #f8fafb;
  grid-template-columns: 85px 500px 247px;
  img {
    width: 85px;
    height: 64px;
  }
  @media (max-width: ${props => props.theme.screens.md}) {
    display: flex;
    padding: 48px 24px 0 24px;
    img {
      margin: auto;
    }
    div {
      display: flex;
      margin: auto;
      text-align: center;
    }
    > div {
      display: contents;
    }
  }
`;

export const Text1 = styled.div`
  font-size: 32px;
  line-height: 36px;
  color: #404040;
  margin: 0 0 0 34px;
  @media (max-width: ${props => props.theme.screens.md}) {
    font-size: 28px;
    line-height: 36px;
      padding: 16px 0 0;
  }
`;

export const Text2 = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #1C7A17;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin: 12px 0 0 34px;
  @media (max-width: ${props => props.theme.screens.md}) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.05em;
    padding-top: 16px;
  }
`;

export const ActivateButton = styled.div`
  display: flex;
  align-content: center;
  padding: 12px 0 0;
  & div:first-of-type {
    margin: auto;
  }
  @media (max-width: ${props => props.theme.screens.md}) {
    padding: 24px 0 0;
  }
`;

export const FrequentlyQuestions = styled.div`
  display: flex;
  width: 247px;
  height: 48px;
  margin-top: 12px !important;
  font-size: 16px;
  line-height: 20px;
  > a {
    margin: auto  !important;
    color: #027AAE;
  }
  @media (max-width: ${props => props.theme.screens.md}) {
    margin-top: 16px !important;
  }
`;

export const GetStartedButton = styled(Button)`
  width: 148px;
  height: 48px;
  && {
    margin: auto;
  }
`;
