import React from 'react';
import PropTypes from 'prop-types';
import '@veneer/core/dist/css/veneer.css';
import VeneerCoachmark from '@veneer/core/dist/scripts/coachmark';
import { CoachContainer } from './styles';

export const Coachmark = ({
  title,
  description,
  content,
}) => {
  const [ref, setRef] = React.useState(undefined);
  const [activeStep, setActiveStep] = React.useState(undefined);

  const handler = event => {
    setActiveStep(prev => {
      // WORKAROUND FIX: prevent multiple coachmarks on the same page
      // SDASH-1953: https://github.azc.ext.hp.com/veneer/veneer/issues/3127
      const closeButtons = document.querySelectorAll('div[rel=coachmark] button');
      closeButtons.forEach(item => item.click());

      return (prev === 0 ? undefined : 0);
    });

    event.stopPropagation();
  };

  // Prevents click events from the workaround to bubble up in the hierarchy.
  const veneerOnClick = event => {
    event.stopPropagation();
  };

  return (
    <>
      <CoachContainer
        ref={setRef}
        onClick={handler}
      >
        {content}
      </CoachContainer>
      <VeneerCoachmark
        onClick={veneerOnClick}
        closeButton
        onClose={() => setActiveStep(undefined)}
        activeStep={activeStep}
        anchorElement={ref}
        steps={[{ title, description }]}
        rel="coachmark"
      />
    </>
  );
};

Coachmark.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.element.isRequired,
  data: PropTypes.shape({
    country: PropTypes.string.isRequired,
    deviceSKU: PropTypes.string.isRequired,
    encryptedClientID: PropTypes.string.isRequired,
    encryptedDeviceID: PropTypes.string.isRequired,
  }).isRequired,
};

Coachmark.defaultProps = {
  title: '',
  description: '',
};

export default Coachmark;
