import styled from 'styled-components';
import Tokens from '@veneer/tokens';
import { SuperscriptLink } from '../../shared-components/Link/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0 4px 16px rgba(33, 33, 33, 0.1);
  border-radius: 16px;
  min-height: 384px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Text = styled.div`
  font-family: ${Tokens.fontTextMedium};
  color: #212121;
`;

export const Header = styled(Text)`
  font-size: 20px;
  line-height: 28px;
  padding-top: 16px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 0;
  font-weight: bold;

  @media (max-width: ${props => props.theme.screens.sm}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Body = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px;
`;

export const StyledLink = styled(SuperscriptLink)`
`;
