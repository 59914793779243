import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  flex-direction: column;
  padding: 32px 24px 24px 24px;
  background-color: #f8fafb;
  grid-template-columns: 1000px;

  @media (max-width: ${props => props.theme.screens.md}) {
    display: flex;
    padding-top: 48px;
  }
`;

export const Line = styled.div`
  margin: 0 40px;
  border: 1px solid #E2E2E2;

  @media (max-width: ${props => props.theme.screens.md}) {
    margin: 0 24px;
  }
`;

export const Text = styled.div`
  padding: 48px 80px 0;

  p, span {
    color: #212121;
    font-size: 14px;
    line-height: 20px;
  }

  & > p:not(:first-child) {
    padding-top: 12px;
  }

  span > p {
    padding-top: 12px;
  }

  & > a {
    color: #027AAE;
    text-decoration: underline;
  }

  @media (max-width: ${props => props.theme.screens.sm}) {
    padding: 48px 24px 0;
  }

  @media (max-width: ${props => props.theme.screens.md}) {
    padding: 64px 24px 0;
  }
`;
