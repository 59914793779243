import React from 'react';
import PropTypes from 'prop-types';
import { useI18n, usePreferencesContext, useRootContext } from '@jarvis/react-portal-addons';
import { GetStartedButtonClickedEvent, publishEvent } from '../../utils/analytics';
import Images from '../../assets/images';
import { PATHS } from '../../utils/constants';
import {
  ButtonContainer,
  Container,
  GetStartedButton,
  Logo,
  StyledBreadcrumbs,
  Text1,
  Text2,
  Text3,
  TextAndButtonContainer,
  TextContainer,
  TopContainer,
} from './styles';
import Printer from '../Printer';
import { SuperscriptLink } from '../shared-components/Link/styles';
import {
  getPrivatePageUrl,
} from '../../utils/globals';

export const Banner = ({
  data,
  isPrivate,
  instantInkMonths,
}) => {
  const { t } = useI18n();
  const { navigation } = useRootContext();
  const preferences = usePreferencesContext();

  const PrinterCard = () => (
    <Printer
      data={data}
      instantInkMonths={instantInkMonths}
    />
  );

  const Breadcrumb = () => {
    if (isPrivate) {
      return (
        <StyledBreadcrumbs
          id="breadcrumb-second-offer"
          items={[
            {
              key: 'dashboard',
              onClick: () => navigation.push(PATHS.HOME),
              text: t('breadcrumb.accountDashboard'),
            },
            {
              key: 'printers',
              onClick: () => navigation.push(PATHS.MY_PRINTERS),
              text: t('breadcrumb.printers'),
            },
            {
              text: t('breadcrumb.activateHpPlus'),
            },
          ]}
        />
      );
    }
    return '';
  };

  const handleGetStarted = () => {
    const getStartedUrl = getPrivatePageUrl(preferences);
    publishEvent(GetStartedButtonClickedEvent('Top'));
    navigation.push(getStartedUrl);
  };

  const Text3Container = () => (
    <Text3>
      {t('home.header.subHeader') }
      &nbsp;
      <SuperscriptLink href="#footnote1">
        1
      </SuperscriptLink>
    </Text3>
  );

  return (
    <Container backgroundImage={Images.orangeTriangle.toString()}>
      <TopContainer>
        <TextContainer>
          <Breadcrumb />
          <Text1>{t('home.header.eyebrow')}</Text1>
          <Text2>{t('home.header.header')}</Text2>
          {
            isPrivate
            && (
              <Text3Container />
            )
          }
          {
            !isPrivate
            && (
              <TextAndButtonContainer>
                <Text3Container />
                <ButtonContainer>
                  <GetStartedButton onClick={handleGetStarted}>
                    {t('home.header.signedOutUser.getStart')}
                  </GetStartedButton>
                </ButtonContainer>
              </TextAndButtonContainer>
            )
          }
        </TextContainer>
        <Logo><img alt="HP+" src={Images.hpPlus} /></Logo>
      </TopContainer>
      {
        isPrivate
        && (
          <PrinterCard />
        )
      }
    </Container>
  );
};

Banner.propTypes = {
  data: PropTypes.shape({
    country: PropTypes.string.isRequired,
    deviceEligibility: PropTypes.shape({
      eligible: PropTypes.bool.isRequired,
      expires: PropTypes.number.isRequired,
    }),
    deviceSKU: PropTypes.string.isRequired,
    encryptedClientID: PropTypes.string.isRequired,
    encryptedDeviceID: PropTypes.string.isRequired,
    printerImages: PropTypes.arrayOf(PropTypes.string),
    printerName: PropTypes.string,
  }),
  isPrivate: PropTypes.bool.isRequired,
  instantInkMonths: PropTypes.number.isRequired,
};

Banner.defaultProps = {
  data: null,
};

export default Banner;
