import React from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import { useI18n } from '@jarvis/react-portal-addons';
import {
  Body,
  Container,
  Header,
  StyledLink,
} from './styles';
import { InnerSpanHTML } from '../../shared-components/InnerHTML';

const Tile = ({
  image, titleKey, bodyKey, footnoteNumbers,
}) => {
  const { t } = useI18n();
  return (
    <Container>
      <img alt={t(titleKey)} src={image} />
      <Header>{t(titleKey)}</Header>
      <Body>
        <InnerSpanHTML>
          {t(...bodyKey)}
        </InnerSpanHTML>
        &nbsp;
        {footnoteNumbers.map(footnote => (
          <StyledLink key={footnote} href={`#footnote${footnote}`}>
            &nbsp;
            {footnote}
          </StyledLink>
        ))}
      </Body>
    </Container>
  );
};

Tile.defaultProps = {
  image: '',
  titleKey: '',
  bodyKey: [],
  footnoteNumbers: [],
};

Tile.propTypes = {
  image: PropTypes.string,
  titleKey: PropTypes.string,
  bodyKey: arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  footnoteNumbers: arrayOf(PropTypes.string),
};

export default Tile;
