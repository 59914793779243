import React from 'react';
import PropTypes from 'prop-types';
import { useI18n, usePreferencesContext, useRootContext } from '@jarvis/react-portal-addons';
import Images from '../../assets/images';
import ActivateOfferModal from '../ActivateOfferModal';
import {
  ActivateButton,
  Container,
  GetStartedButton,
  Text1,
  Text2,
} from './styles';
import {
  getPrivatePageUrl,
} from '../../utils/globals';
import { epochToDate } from '../shared-components/DateTime';
import { GetStartedButtonClickedEvent, publishEvent } from '../../utils/analytics';

const GetMore = ({
  data,
  isPrivate,
  instantInkMonths,
}) => {
  const { t } = useI18n();
  const { navigation } = useRootContext();
  const timestamp = data && data.deviceEligibility && data.deviceEligibility.expires;
  const eligible = data && data.deviceEligibility && data.deviceEligibility.eligible;
  const preferences = usePreferencesContext();

  const handleGetStarted = () => {
    const getStartedUrl = getPrivatePageUrl(preferences);
    publishEvent(GetStartedButtonClickedEvent('Bottom'));
    navigation.push(getStartedUrl);
  };

  const TextExpiration = () => {
    if (isPrivate && data && data.printerName) {
      return (
        <Text2>
          {t('home.getMore.bodyCopySignedIn', {
            dateExpiration: epochToDate(timestamp),
            interpolation: { escapeValue: false },
          })}
        </Text2>
      );
    }
    return '';
  };

  const ActivateOffer = () => {
    if (isPrivate && data && data.printerName) {
      return (
        <ActivateOfferModal
          eligible={eligible}
          instantInkMonths={instantInkMonths}
          data={data}
          source="get-more-card"
        />
      );
    }
    return (
      <GetStartedButton onClick={handleGetStarted}>
        {t('home.getMore.primaryButton')}
      </GetStartedButton>
    );
  };

  return (
    <Container>
      <div>
        <img alt="HP+" src={Images.hpPlusBlue} />
      </div>
      <div>
        <Text1>
          { t('home.getMore.header') }
        </Text1>
        <TextExpiration />
      </div>
      <div>
        <ActivateButton>
          {
           ActivateOffer()
          }
        </ActivateButton>
      </div>
    </Container>
  );
};

GetMore.propTypes = {
  data: PropTypes.shape({
    country: PropTypes.string.isRequired,
    deviceEligibility: PropTypes.shape({
      eligible: PropTypes.bool.isRequired,
      expires: PropTypes.number.isRequired,
    }),
    deviceSKU: PropTypes.string.isRequired,
    encryptedClientID: PropTypes.string.isRequired,
    encryptedDeviceID: PropTypes.string.isRequired,
    printerName: PropTypes.string,
  }).isRequired,
  instantInkMonths: PropTypes.number.isRequired,
  isPrivate: PropTypes.bool.isRequired,
};

export default GetMore;
