import styled from 'styled-components';
import Button from '@veneer/core/dist/scripts/button';
import Breadcrumbs from '@veneer/core/dist/scripts/breadcrumbs';
import Tokens from '@veneer/tokens';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 195px;
  background-image: url("${props => props.backgroundImage}");
  background-repeat: no-repeat;
  background-position: right top;
  background-color: #f8fafb;
  background-size: 260px;
  padding: 0 24px 20px 40px;

  @media (max-width: ${props => props.theme.screens.md}) {
    background-size: 260px;
    padding: 0 24px 24px;
  }

  @media (max-width: ${props => props.theme.screens.sm}) {
    background-size: 210px;
    padding: 0 24px 24px;
  }

  @media (max-width: ${props => props.theme.screens.xsm}) {
    background-size: 150px;
    padding: 0 6px 24px 24px;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 936px;
`;

const Text = styled.div`
`;

export const Text1 = styled(Text)`
  color: #E06712;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding-top: 36px;

  @media (max-width: ${props => props.theme.screens.sm}) {
    font-size: 16px;
  }
`;

export const Text2 = styled.div`
  font-size: 48px;
  line-height: 64px;
  letter-spacing: 0em;

  @media (max-width: ${props => props.theme.screens.sm}) {
    max-width: 500px;
    font-size: 36px;
    line-height: 44px;
  }
`;

export const Text3 = styled.span`
  display: inline-block;
  font-family: ${Tokens.fontTextMedium};

  color: #E06712;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0em;
  padding-top: 32px;

  @media (max-width: ${props => props.theme.screens.sm}) {
    font-size: 24px;
  }
`;

export const TextAndButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;

  @media (max-width: ${props => props.theme.screens.md}) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const Logo = styled.div`
  margin-left: auto;
  margin-top: 0;
  padding-top: 50px;

  @media (max-width: ${props => props.theme.screens.sm}) {
    padding-top: 24px;
    img {
      transform: scale(0.7);
    }
  }

  @media (max-width: ${props => props.theme.screens.xsm}) {
    padding-top: 6px;
    img {
      transform: scale(0.5);
    }
  }
`;

export const ButtonContainer = styled.div`
  padding-left: 20px;
  margin-left: auto;
  margin-right: 80px;
  padding-top: 32px;

  @media (max-width: ${props => props.theme.screens.md}) {
    margin-left: 0;
    padding-left: 0;
    padding-top: 20px;
  }
`;

export const GetStartedButton = styled(Button)`
  width: 148px;
  height: 48px;
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  font-size: 14px;
  line-height: 20px;
  margin: 28px 0 0 0;
`;

export default Container;
