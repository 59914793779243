export const Theme = {
  screens: {
    xxs: '375px',
    xsm: '576px',
    sm: '768px',
    md: '991px',
    lg: '1024px',
    xlg: '1440px',
    xxl: '1660px',
    ul: '1920px',
    // desktop app breakpoints
    dxsm: '836px',
    dsm: '1028px',
    dsmm: '1100px',
    dmd: '1250px',
    dlg: '1284px',
    dxlg: '1700px',
    dxxl: '1940px',
  },
};

export const mergeThemeWithEnvConfigInfo = cfg => {
  const config = cfg || {};
  return {
    host: {
      isNative: config.isNative,
      isDesktopApp: config.isJWebDesktopApp,
      isiOSApp: config.isJWebiOSApp,
      isAndroidApp: config.isJWebAndroidApp,
    },
    ...Theme,
  };
};
