import PropTypes from 'prop-types';
import { path } from 'ramda';

const activity = 'HpPlusSecondOffer-v01';
const screenPath = '/ReactConsumerSecondOffer/';
const eventDetailVersion = '1.4.0';

export const publishEvent = event => {
  const publishCdmEvents = path(['Shell', 'v1', 'analytics', 'publishCdmEvents'], window);

  if (!publishCdmEvents) {
    return;
  }

  const dateTime = new Date().toISOString();
  const cdmEvents = {
    dateTime,
    eventDetailType: 'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    eventDetail: event,
    version: '1.4.0',
  };

  publishCdmEvents([cdmEvents]);
};

export const AnalyticsEventPropTypes = {
  action: PropTypes.string.isRequired,
  activity: PropTypes.string.isRequired,
  screenPath: PropTypes.string.isRequired,
  screenName: PropTypes.string.isRequired,
  screenMode: PropTypes.string.isRequired,
  controlName: PropTypes.string.isRequired,
  controlDetail: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};

const ACTIONS = {
  CONTROL_BUTTON_CLICKED: 'ControlButtonClicked',
  CONTROL_HYPERLINK_CLICKED: 'ControlHyperLinkClicked',
  CONTROL_TOGGLE_DISABLED: 'ControlToggleDisabled',
  CONTROL_TOGGLE_ENABLED: 'ControlToggleEnabled',
  MODAL_WINDOW_DISPLAYED: 'ModalWindowDisplayed',
  MODULE_DISPLAYED: 'ModuleDisplayed',
  MODULE_INITIALIZED: 'ModuleInitialized',
  SCREEN_DISPLAYED: 'ScreenDisplayed',
};

export const ScreenDisplayedEvent = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath,
  screenName: 'HpPlusSecondOffer',
  version: eventDetailVersion,
};

export const GetStartedButtonClickedEvent = controlDetail => ({
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'HpPlusSecondOffer',
  controlName: 'GetStarted',
  controlDetail,
  version: eventDetailVersion,
});

export const ActivateHpPlusButtonClickedEvent = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: 'HpPlusSecondOffer',
  controlName: 'ActivateHpPlus',
  version: eventDetailVersion,
};

export const ActivateHpPlusModalDisplayedEvent = {
  action: ACTIONS.MODAL_WINDOW_DISPLAYED,
  activity,
  screenPath: `${screenPath}HpPlusSecondOffer/`,
  screenName: 'ActivateHpPlusModal',
  version: eventDetailVersion,
};

export const ActivateHpPlusModalCloseEvent = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: `${screenPath}HpPlusSecondOffer/`,
  screenName: 'ActivateHpPlusModal',
  controlName: 'Close',
  version: eventDetailVersion,
};

export const ActivateHpPlusModalActivateEvent = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: `${screenPath}HpPlusSecondOffer/`,
  screenName: 'ActivateHpPlusModal',
  controlName: 'ActivateHpPlus',
  version: eventDetailVersion,
};

export const RedeemInstantInkModalDisplayedEvent = {
  action: ACTIONS.MODAL_WINDOW_DISPLAYED,
  activity,
  screenPath: `${screenPath}HpPlusSecondOffer/`,
  screenName: 'RedeemInstantInkModal',
  version: eventDetailVersion,
};

export const RedeemInstantInkModalSkipEvent = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: `${screenPath}HpPlusSecondOffer/`,
  screenName: 'RedeemInstantInkModal',
  controlName: 'SkipInkBenefit',
  version: eventDetailVersion,
};

export const RedeemInstantInkModalRedeemEvent = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: `${screenPath}HpPlusSecondOffer/`,
  screenName: 'RedeemInstantInkModal',
  controlName: 'Continue',
  version: eventDetailVersion,
};

export const UnableToActivateModalDisplayedEvent = {
  action: ACTIONS.MODAL_WINDOW_DISPLAYED,
  activity,
  screenPath: `${screenPath}HpPlusSecondOffer/`,
  screenName: 'UnableToActivateHpPlusModal',
  version: eventDetailVersion,
};

export const UnableToActivateRetryEvent = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: `${screenPath}HpPlusSecondOffer/`,
  screenName: 'UnableToActivateHpPlusModal',
  controlName: 'Retry',
  version: eventDetailVersion,
};

export const UnableToActivateCloseEvent = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: `${screenPath}HpPlusSecondOffer/`,
  screenName: 'UnableToActivateHpPlusModal',
  controlName: 'Close',
  version: eventDetailVersion,
};

export const UnableToActivateGetSupportEvent = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: `${screenPath}HpPlusSecondOffer/`,
  screenName: 'UnableToActivateHpPlusModal',
  controlName: 'GetSupport',
  version: eventDetailVersion,
};

export const KeyPointLinkClickedEvent = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath: `${screenPath}`,
  screenName: 'HpPlusSecondOffer',
  controlName: 'KeyPointIntelligenceInstantInk',
  version: eventDetailVersion,
};

export const InstantInkLinkClickedEvent = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath: `${screenPath}`,
  screenName: 'HpPlusSecondOffer',
  controlName: 'InstantInkDotCom',
  version: eventDetailVersion,
};

export const PrintingRequirementLinkClickedEvent = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath: `${screenPath}`,
  screenName: 'HpPlusSecondOffer',
  controlName: 'PrintingRequirements',
  version: eventDetailVersion,
};

export const HpSmartLinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath: `${screenPath}`,
  screenName: 'HpPlusSecondOffer',
  controlName: 'HpSmart',
  version: eventDetailVersion,
};

export const ForestFirstLinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath: `${screenPath}`,
  screenName: 'HpPlusSecondOffer',
  controlName: 'ForestFirst',
  version: eventDetailVersion,
};

export const LimtedWarrentyLinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath: `${screenPath}`,
  screenName: 'HpPlusSecondOffer',
  controlName: 'LimitedWarranty',
  version: eventDetailVersion,
};

export const HpRecycleLinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath: `${screenPath}`,
  screenName: 'HpPlusSecondOffer',
  controlName: 'HpRecycle',
  version: eventDetailVersion,
};

export const MobilePrintingLinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath: `${screenPath}`,
  screenName: 'HpPlusSecondOffer',
  controlName: 'MobilePrinting',
  version: eventDetailVersion,
};
