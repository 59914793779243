import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-template-columns: 456px 456px;
  padding: 40px;
  grid-gap: 24px;
  background-color: #f8fafb;

  @media (max-width: ${props => props.theme.screens.dmd}) {
    grid-template-columns: 456px;
  }

  @media (max-width: 520px) {
    grid-template-columns: auto;
  }
`;

export default Container;
