import React from 'react';
import PropTypes from 'prop-types';
import { useI18n } from '@jarvis/react-portal-addons';
import { Coachmark } from '../Coachmark';
import Images from '../../assets/images';
import {
  CellHpPlus,
  CellLabels,
  CellText,
  CellTextGrid,
  CellTitle,
  CellTitleOrange,
  CellWithoutHPPlus,
  ContainerDesktop,
  ContainerMobile,
  ContainerSubHeaderMobile,
  Header,
  IconInformation,
  Line,
  LineBold,
  LineMobile,
  MobileGrid,
  MobileGridHeader,
  MobileHeaderText,
  MobileText,
  MobileTextUppercase,
  OnlyNow,
  Spacer,
  StyledLinkDesktop,
  StyledLinkHeaderMobile,
  StyledLinkMobile,
  Table,
  TableMobile,
  Text1,
  Text2,
  VerticalLine,
} from './styles';
import { getStratusAccessTokenFromCookie } from '../../utils/auth';

const ORANGE = '#D06702';
const DARK = '#404040';

const CheckMark = ({ fontColor }) => (
  fontColor === ORANGE
    ? <img alt="Checkmark" src={Images.checkmarkOrange} />
    : <img alt="Checkmark" src={Images.checkmark} />
);

CheckMark.propTypes = {
  fontColor: PropTypes.string.isRequired,
};

const userIsSignedInPublicPage = isPrivate => !isPrivate && getStratusAccessTokenFromCookie();

const CellTextWithCheckmark = ({
  cellText,
  hideCheckmark,
  fontColor,
  infoText,
  data,
  source,
}) => (
  <CellTextGrid>
    {
      hideCheckmark
        ? <div />
        : <CheckMark fontColor={fontColor} />
    }
    <CellText fontColor={fontColor}>{cellText}</CellText>
    {
      infoText
        ? (
          <Coachmark
            description={infoText}
            content={<IconInformation color="colorHpBlue6" />}
            data={data}
            source={source}
          />
        )
        : <div />
    }
  </CellTextGrid>
);

CellTextWithCheckmark.propTypes = {
  cellText: PropTypes.string,
  hideCheckmark: PropTypes.bool,
  fontColor: PropTypes.string,
  infoText: PropTypes.string,
  data: PropTypes.shape({
    country: PropTypes.string.isRequired,
    deviceSKU: PropTypes.string.isRequired,
    encryptedClientID: PropTypes.string.isRequired,
    encryptedDeviceID: PropTypes.string.isRequired,
  }),
  source: PropTypes.string,
};

CellTextWithCheckmark.defaultProps = {
  cellText: '',
  hideCheckmark: false,
  fontColor: DARK,
  infoText: '',
  data: null,
  source: '',
};

const TrialAndMoreDesktop = ({
  isPrivate,
  instantInkMonths,
  noInstantInk,
  data,
}) => {
  const { t } = useI18n();
  const generateFootnote = footnote => (
    <StyledLinkDesktop href={`#footnote${footnote}`}>
      {footnote}
    </StyledLinkDesktop>
  );

  const getHeader = () => {
    let header;

    if (noInstantInk) {
      header = t('home.comparationTable.headerNonInstantInk');
    } else {
      header = isPrivate
        ? t('home.comparationTable.headerDesktop', { months: instantInkMonths })
        : t('home.comparationTable.headerDesktopGeneric');
    }

    return (
      <Text1>
        {header}
      </Text1>
    );
  };

  return (
    <ContainerDesktop>
      {getHeader()}
      {!noInstantInk && (
        <Text2>
          {isPrivate
            ? t(((instantInkMonths > 1)
              ? 'home.comparationTable.subHeaderDesktop'
              : 'home.comparationTable.subHeaderDesktop1Month'),
            { months: instantInkMonths })
            : t('home.comparationTable.subHeaderDesktopGeneric')}
          &nbsp;
          {generateFootnote(4)}
        </Text2>
      )}
      <Table>
        <Spacer />
        <Spacer />
        <OnlyNow>{t('home.comparationTable.hp+Column.desktop.limitedOffer')}</OnlyNow>
        <CellLabels>
          <CellTitle />
          <Spacer />
          <CellText>
            {t('home.comparationTable.columnsBenefits.hpWarranty')}
            &nbsp;
            {generateFootnote(noInstantInk ? 4 : 7)}
          </CellText>
          <Line />
          {!noInstantInk && !userIsSignedInPublicPage(isPrivate) && (
            <>
              <CellText>
                {t('home.comparationTable.columnsBenefits.optionalInstant')}
                &nbsp;
                {generateFootnote(4)}
              </CellText>
              <Line />
            </>
          )}
          <CellText>
            {t('home.comparationTable.columnsBenefits.advancedScanning')}
            &nbsp;
            {generateFootnote(noInstantInk ? 2 : 5)}
          </CellText>
          <Line />
          <CellText>{t('home.comparationTable.columnsBenefits.exclusivePrinter')}</CellText>
          <Line />
          <CellText>{t('home.comparationTable.columnsBenefits.builtInPrinter')}</CellText>
          <Line />
          <CellText>{t('home.comparationTable.columnsBenefits.printingAnywhere')}</CellText>
          <Line />
          <CellText>{t('home.comparationTable.columnsBenefits.keepPrintsPrivate')}</CellText>
          <Line />
          <CellText>{t('home.comparationTable.columnsBenefits.smartDashboard')}</CellText>
          <Line />
          <CellText>
            {t('home.comparationTable.columnsBenefits.helpsProtect')}
            &nbsp;
            {noInstantInk && generateFootnote(3)}
          </CellText>
          {/* <Line />
        <CellText>Original HP Ink, HP account, and Internet connection</CellText> */}
        </CellLabels>
        <CellWithoutHPPlus>
          <CellTitle>{t('home.comparationTable.withoutHp+Column.header')}</CellTitle>
          <LineBold />
          <CellTextWithCheckmark cellText={t('home.comparationTable.withoutHp+Column.standard')} />
          <Line />
          {!noInstantInk && !userIsSignedInPublicPage(isPrivate) && (
            <>
              <CellTextWithCheckmark />
              <Line />
            </>
          )}
          <CellTextWithCheckmark hideCheckmark />
          <Line />
          <CellTextWithCheckmark hideCheckmark />
          <Line />
          <CellTextWithCheckmark hideCheckmark />
          <Line />
          <CellTextWithCheckmark hideCheckmark />
          <Line />
          <CellTextWithCheckmark hideCheckmark />
          <Line />
          <CellTextWithCheckmark hideCheckmark />
          <Line />
          <CellTextWithCheckmark hideCheckmark />
        </CellWithoutHPPlus>
        <CellHpPlus>
          <CellTitleOrange>{t('home.comparationTable.hp+Column.header')}</CellTitleOrange>
          <LineBold />
          <CellTextWithCheckmark
            cellText={t('home.comparationTable.hp+Column.additionalYear')}
            fontColor={ORANGE}
            showInfo
            infoText={t('home.comparationTable.hp+InfoOverlay.activateHp+')}
            data={data}
            source="hp-warranty"
          />
          <Line />
          {!noInstantInk && !userIsSignedInPublicPage(isPrivate) && (
            <>
              <CellTextWithCheckmark
                cellText={isPrivate
                  ? t((instantInkMonths > 1)
                    ? 'home.comparationTable.hp+Column.sixMonthsIncluded'
                    : 'home.comparationTable.hp+Column.sixMonthsIncluded1Month',
                  { months: instantInkMonths })
                  : t('home.comparationTable.hp+Column.sixMonthsIncludedGeneric')}
                fontColor={ORANGE}
                infoText={t('home.comparationTable.hp+InfoOverlay.activateHp+Trial')}
                data={data}
                source="instant-ink-trial"
              />
              <Line />
            </>
          )}
          <CellTextWithCheckmark cellText="" fontColor={ORANGE} />
          <Line />
          <CellTextWithCheckmark cellText="" fontColor={ORANGE} />
          <Line />
          <CellTextWithCheckmark cellText="" fontColor={ORANGE} />
          <Line />
          <CellTextWithCheckmark cellText="" fontColor={ORANGE} />
          <Line />
          <CellTextWithCheckmark cellText="" fontColor={ORANGE} />
          <Line />
          <CellTextWithCheckmark cellText="" fontColor={ORANGE} />
          <Line />
          <CellTextWithCheckmark cellText="" fontColor={ORANGE} />
        </CellHpPlus>
      </Table>
    </ContainerDesktop>
  );
};

TrialAndMoreDesktop.propTypes = {
  isPrivate: PropTypes.bool.isRequired,
  instantInkMonths: PropTypes.number.isRequired,
  noInstantInk: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    country: PropTypes.string.isRequired,
    deviceSKU: PropTypes.string.isRequired,
    encryptedClientID: PropTypes.string.isRequired,
    encryptedDeviceID: PropTypes.string.isRequired,
  }).isRequired,
};

const TrialAndMoreMobile = ({
  isPrivate,
  instantInkMonths,
  noInstantInk,
}) => {
  const { t } = useI18n();
  const generateFootnote = footnote => (
    <StyledLinkMobile href={`#footnote${footnote}`}>
      {footnote}
    </StyledLinkMobile>
  );

  return (
    <ContainerMobile>
      <Header>
        <Text1>
          {noInstantInk && t('home.comparationTable.headerNonInstantInk')}
          {!noInstantInk && (
            <>
              {t('home.comparationTable.headerMobile')}
              &nbsp;
              <StyledLinkHeaderMobile href="#footnote1">1</StyledLinkHeaderMobile>
            </>
          )}
        </Text1>
        <ContainerSubHeaderMobile>
          <Text2>
            {t('home.comparationTable.subHeaderMobile.text1')}
            &nbsp;
          </Text2>
        </ContainerSubHeaderMobile>
      </Header>
      <MobileGridHeader>
        <MobileHeaderText fontColor={DARK}>{t('home.comparationTable.withoutHp+Column.header')}</MobileHeaderText>
        <VerticalLine />
        <MobileHeaderText fontColor={ORANGE}>{t('home.comparationTable.hp+Column.header')}</MobileHeaderText>
      </MobileGridHeader>
      <TableMobile>
        <MobileTextUppercase>
          {t('home.comparationTable.columnsBenefits.hpWarranty')}
          &nbsp;
          {generateFootnote(noInstantInk ? 4 : 7)}
        </MobileTextUppercase>
        <MobileGrid>
          <MobileText fontColor={DARK}>{t('home.comparationTable.withoutHp+Column.standard')}</MobileText>
          <VerticalLine />
          <MobileText fontColor={ORANGE}>{t('home.comparationTable.hp+Column.additionalYear')}</MobileText>
        </MobileGrid>
        <LineMobile />
        {!noInstantInk && !userIsSignedInPublicPage(isPrivate) && (
          <>
            <MobileTextUppercase>
              {t('home.comparationTable.columnsBenefits.optionalInstant')}
              &nbsp;
              {generateFootnote(4)}
            </MobileTextUppercase>
            <MobileGrid>
              <img alt="Checkmark" src={Images.checkmark} />
              <VerticalLine />
              <MobileText fontColor={ORANGE}>
                {isPrivate
                  ? t((instantInkMonths > 1)
                    ? 'home.comparationTable.hp+Column.sixMonthsIncluded'
                    : 'home.comparationTable.hp+Column.sixMonthsIncluded1Month',
                  { months: instantInkMonths })
                  : t('home.comparationTable.hp+Column.sixMonthsIncludedGeneric')}
              </MobileText>
            </MobileGrid>
            <LineMobile />
          </>
        )}
        <MobileTextUppercase>
          {t('home.comparationTable.columnsBenefits.advancedScanning')}
          &nbsp;
          {generateFootnote(noInstantInk ? 2 : 5)}
        </MobileTextUppercase>
        <MobileGrid>
          <img alt="X" src={Images.x} />
          <VerticalLine />
          <MobileText fontColor={ORANGE} />
        </MobileGrid>
        <LineMobile />
        <MobileTextUppercase>{t('home.comparationTable.columnsBenefits.exclusivePrinter')}</MobileTextUppercase>
        <MobileGrid>
          <img alt="X" src={Images.x} />
          <VerticalLine />
          <img alt="Checkmark" src={Images.checkmarkOrange} />
        </MobileGrid>
        <LineMobile />
        <MobileTextUppercase>{t('home.comparationTable.columnsBenefits.builtInPrinter')}</MobileTextUppercase>
        <MobileGrid>
          <img alt="X" src={Images.x} />
          <VerticalLine />
          <img alt="Checkmark" src={Images.checkmarkOrange} />
        </MobileGrid>
        <LineMobile />
        <MobileTextUppercase>{t('home.comparationTable.columnsBenefits.printingAnywhere')}</MobileTextUppercase>
        <MobileGrid>
          <img alt="X" src={Images.x} />
          <VerticalLine />
          <img alt="Checkmark" src={Images.checkmarkOrange} />
        </MobileGrid>
        <LineMobile />
        <MobileTextUppercase>{t('home.comparationTable.columnsBenefits.keepPrintsPrivate')}</MobileTextUppercase>
        <MobileGrid>
          <img alt="X" src={Images.x} />
          <VerticalLine />
          <img alt="Checkmark" src={Images.checkmarkOrange} />
        </MobileGrid>
        <LineMobile />
        <MobileTextUppercase>{t('home.comparationTable.columnsBenefits.smartDashboard')}</MobileTextUppercase>
        <MobileGrid>
          <img alt="X" src={Images.x} />
          <VerticalLine />
          <img alt="Checkmark" src={Images.checkmarkOrange} />
        </MobileGrid>
        <LineMobile />
        <MobileTextUppercase>
          {t('home.comparationTable.columnsBenefits.helpsProtect')}
          &nbsp;
          {noInstantInk && generateFootnote(3)}
        </MobileTextUppercase>
        <MobileGrid>
          <img alt="X" src={Images.x} />
          <VerticalLine />
          <img alt="Checkmark" src={Images.checkmarkOrange} />
        </MobileGrid>
        <LineMobile />
      </TableMobile>
    </ContainerMobile>
  );
};

TrialAndMoreMobile.propTypes = {
  isPrivate: PropTypes.bool.isRequired,
  instantInkMonths: PropTypes.number.isRequired,
  noInstantInk: PropTypes.bool.isRequired,
};

const TrialAndMore = ({
  isPrivate,
  instantInkMonths,
  noInstantInk,
  data,
}) => (
  <>
    <TrialAndMoreDesktop
      isPrivate={isPrivate}
      instantInkMonths={instantInkMonths}
      noInstantInk={noInstantInk}
      data={data}
    />
    <TrialAndMoreMobile
      isPrivate={isPrivate}
      instantInkMonths={instantInkMonths}
      noInstantInk={noInstantInk}
    />
  </>
);

TrialAndMore.propTypes = {
  isPrivate: PropTypes.bool.isRequired,
  instantInkMonths: PropTypes.number.isRequired,
  data: PropTypes.shape({
    country: PropTypes.string.isRequired,
    deviceSKU: PropTypes.string.isRequired,
    encryptedClientID: PropTypes.string.isRequired,
    encryptedDeviceID: PropTypes.string.isRequired,
  }).isRequired,
  noInstantInk: PropTypes.bool.isRequired,
};

export default TrialAndMore;
