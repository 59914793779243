import { Stack } from '@jarvis/web-stratus-client';

const getGeminiDomain = stack => {
  const urlByStack = {
    [Stack.dev]: 'https://instantink-pie1.hpconnectedpie.com',
    [Stack.pie]: 'https://instantink-pie1.hpconnectedpie.com',
    [Stack.stage]: 'https://instantink-stage1.hpconnectedstage.com',
    [Stack.prod]: 'https://instantink.hpconnected.com',
  };
  return urlByStack[stack] || urlByStack[Stack.prod];
};

export const InstantInkBaseUrl = stack => `${getGeminiDomain(stack)}/enroll/start_v2_web`;

export default InstantInkBaseUrl;
