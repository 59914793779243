const DEFAULT_PREFERENCES = {
  paths: {
    myPrinters: '/ucde/my-printers',
  },
  data: {
    deviceEligibility: {},
  },
};

export default DEFAULT_PREFERENCES;
