import React from 'react';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';
import { mergeThemeWithEnvConfigInfo } from './theme-provider';
import { getEnvironmentConfig } from './utils/auth';
import dataMock from './mock/mock-data.json';
import Home from './pages/Home';

export const history = createBrowserHistory();

const useMock = false;

const App = ({
  properties: { data } = { data: null },
}) => {
  const environmentConfig = getEnvironmentConfig();
  const theme = mergeThemeWithEnvConfigInfo(environmentConfig);
  return (
    <ThemeProvider theme={theme}>
      <Home
        data={useMock ? dataMock : data}
      />
    </ThemeProvider>
  );
};

App.propTypes = {
  properties: PropTypes.shape({
    data: PropTypes.objectOf(PropTypes.shape),
  }),
};

App.defaultProps = {
  properties: null,
};

export default App;
