import { isAndroid, isIOS, isMobile } from 'react-device-detect';

const STRATUS_ACCESS_TOKEN = 'stratus-access-token';

const jWeb = window.JWeb;

const isJWebAuthPluginValid = () => jWeb && jWeb.Plugins && jWeb.Plugins.Auth;
const isJWebDesktopApp = () => !isMobile && isJWebAuthPluginValid() && jWeb && jWeb.isNative;

const isJWebiOSApp = () => isIOS && isJWebAuthPluginValid() && jWeb && jWeb.isNative;
const isJWebAndroidApp = () => isAndroid && isJWebAuthPluginValid() && jWeb && jWeb.isNative;

export const getEnvironmentConfig = () => (
  {
    isNative: window.JWeb && window.JWeb.isNative,
    isJWebDesktopApp: isJWebDesktopApp(),
    isJWebiOSApp: isJWebiOSApp(),
    isJWebAndroidApp: isJWebAndroidApp(),
  }
);

const encodeProperty = property => encodeURIComponent(property);

// Method copied from ucde-portal
const getCookie = cookieName => {
  const { cookie = '' } = document;
  // From https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_document.cookie_framework
  // the craft routing stuff references the "gnb_locale" cookie, that will be our cookie-source of language truth
  const cookieNameEncoded = encodeProperty(cookieName);
  const contentLanguage = decodeURIComponent(
    cookie.replace(
      new RegExp(`(?:(?:^|.*;)\\s*${cookieNameEncoded}\\s*\\=\\s*([^;]*).*$)|^.*$`),
      '$1',
    ),
  ) || '';
  const cookieContent = (Array.isArray(contentLanguage) && contentLanguage.includes(','))
    ? contentLanguage.split(',')[0]
    : contentLanguage;
  return cookieContent;
};

export const getStratusAccessTokenFromCookie = () => getCookie(STRATUS_ACCESS_TOKEN);
