import styled from 'styled-components';
import Tokens from '@veneer/tokens';
import { InnerDivHTML } from '../shared-components/InnerHTML';
import { SuperscriptLink } from '../shared-components/Link/styles';

export const MainContainer = styled.div`
  display: block;
  padding: 38px;
  background-color: #f8fafb;

  @media (max-width: ${props => props.theme.screens.md}) {
    padding: 24px;
  }
`;

export const CardContainer = styled.div`
  display: grid;
  flex-direction: column;
  gap: 24px;
  grid-template-columns: 456px 456px;

  @media (max-width: ${props => props.theme.screens.lg}) {
    grid-template-columns: 456px;
  }

  @media (max-width: ${props => props.theme.screens.md}) {
    grid-template-columns: 456px;
  }

  @media (max-width: ${props => props.theme.screens.sm}) {
    grid-template-columns: auto;
  }
`;

export const Img = styled.img`
  display: flex;
  width: 56px;
  height: 56px;
`;

export const Text2 = styled(InnerDivHTML)`
  display: contents;
  span {
    font-family: ${Tokens.fontTextMedium};
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;

    @media (max-width: ${props => props.theme.screens.sm}) {
      font-size: 18px;
    }

  }
`;

export const Text3 = styled.div`
  min-height: 56px;
  padding-left: 10px;
  align-self: center;
  font-size: 20px;
  line-height: 28px;

  @media (max-width: ${props => props.theme.screens.sm}) {
    font-size: 18px;
  }
`;

export const Text1 = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: #D06702;
  padding-bottom: 40px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media (max-width: ${props => props.theme.screens.sm}) {
    font-size: 16px;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledLink = styled(SuperscriptLink)`
`;
