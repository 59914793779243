import styled from 'styled-components';
import AlertModal from '@veneer/core/dist/scripts/alert_modal/alert_modal';
import Button from '@veneer/core/dist/scripts/button';
import Tokens from '@veneer/tokens';

export const OfferTermsList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style-type: disc;
    margin: 0 30px;

    li {
        padding-left: 10px;
        margin: 0 65px;
        width: 100%;
        font-size: 14px;
        line-height: 16px;
    }

    li:not(:last-child):after {
        content: '';
        height: 1px;
        display: block;
        background: #E2E2E2;
        margin: 18px -40px;
    }

    @media (max-width: 767px) {
        li {
            margin: 0 35px;
        }

        li:not(:last-child):after {
            margin: 18px -60px;
        }
    }
`;

export const OfferTerms = styled.p`
    font-size: 14px !important;
    line-height: 16px !important;
    text-align: center;
    margin: 24px 30px 0 30px;
`;

export const ProgressContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    & * {
        margin: 0 auto;
    }

    & div[role="progressbar"] {
        height: 34px;
        width: 34px;
        margin-bottom: 20px;
    }

    p {
      font-size: 18px;
      text-align: center;
    }
`;

export const TextContainer = styled.div`
  text-align: center;
  padding-top: 12px;
  line-height: 21px;
  font-size: 18px;

  img {
    padding-bottom: 16px;
  }

  p {
      margin-bottom: 12px;
      span {
      }
  }
`;

export const ModalTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  text-align: center;
  color: #4E4E4E;
  padding-bottom: 16px;
`;

export const AlertModalIcon = styled(AlertModal)`
    & div .vn-modal--content {
      width: calc(100% - 48px);
      max-width: 482px;
    }

    & div[role="dialog"] {
        min-height: 506px;
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
    }

    & h4[id="alert-modal-label"] {
        font-family: ${Tokens.fontTextMedium};
        font-size: 24px !important;
        line-height: 28px !important;
        text-align: center;
    }

    & h4[id="alert-modal-label"]:before {
        content: url(${props => props.icon});
        display: block;
        padding-left: 16px;
        margin: 0 auto;
        margin-bottom: 14px;
    }

    & h4[id="alert-modal-label"]:after {
        ${props => props.subtitle && `
            content: '${props.subtitle}';
            font-size: 18px !important;
            line-height: 21px !important;
            display: block;
            margin-top: 8px;
        `}
    }

    & div[class="vn-modal--footer"] {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    & div[class="vn-modal--footer"] a {
        cursor: pointer;
        text-align: center;
        text-decoration: none;
    }

    & div[class="vn-modal--body"] {
      min-height: 180px;
    }

    @media (max-width: 767px) {
        align-items: center !important;

        & div[class="vn-modal--body"] {
            overflow-x: hidden !important;
            overflow-y: auto !important;
            padding-right: 5px;
        }
    }
`;

export const StyledButtonError = styled(Button)`
    width: 126px;
    height: 48px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  align-items: center;
  gap: 12px;
  width: 100%;
  justify-content: center;
`;

export const LinkButtonGroup = styled.div`
  > a.button {
    align-items: center;
    background-color: #027AAE;
    border-color: #027AAE;
    border-radius: 12px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: ${Tokens.fontFamilyLight};
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-width: 112px;
    padding: 11px 31px;
    position: relative;
    text-align: center;
    transition: background-color 0.3s ease,border-color 0.3s ease,color 0.3s ease;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    outline: 0;
    box-shadow: none;
    text-decoration: none !important;
    &:active {
      background-color: #014667;
      border-color: #014667;
    }
    &:hover {
      background-color: #035c84;
      border-color: #035c84;
      text-decoration: none;
    }
    &.secondary {
      background-color: transparent;
      color: #027AAE;
      &:active {
        background-color: #F2F8FB;
        color: #014667;
      }
      &:hover {
        background-color: #F2F8FB;
        color: #035c84;
      }
    }
  }
`;
