import styled from 'styled-components';
import Button from '@veneer/core/dist/scripts/button';
import Tokens from '@veneer/tokens';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(33, 33, 33, 0.101961);
  border-radius: 16px;
  padding: 32px;
  width: 721px;
  margin-bottom: 20px;
  margin-top: 32px;

  @media (max-width: ${props => props.theme.screens.sm}) {
    flex-direction: column;
  }

  @media (max-width: ${props => props.theme.screens.md}) {
    width: auto;
    max-width: 721px;
  }

  @media (max-width: ${props => props.theme.screens.xsm}) {
    margin-right: 18px;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.div`
  font-family: ${Tokens.fontTextMedium};
  padding-bottom: 16px;
`;

export const Text1 = styled(Text)`
  font-size: 22px;
  line-height: 28px;
  color: #212121;
`;

export const Text2 = styled(Text)`
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #1C7A17;
`;

export const ActivateButton = styled(Button)`
  width: 148px;
`;

export const PrinterImage = styled.div`
  padding-right: 32px;

  img {
    width: 140px;
    height: auto;
  }

  @media (max-width: ${props => props.theme.screens.sm}) {
    padding-bottom: 8px;
  }

  @media (max-width: ${props => props.theme.screens.xsm}) {
    img {
      width: 98px;
    }
  }
`;
