/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

export const InnerDivHTML = ({ children, className }) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: children }} />
);

InnerDivHTML.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

export const InnerSpanHTML = ({ children, className, id }) => (
  <span
    id={id} className={className}
    dangerouslySetInnerHTML={{ __html: children }}
  />
);

InnerSpanHTML.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
