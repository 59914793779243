import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useI18n } from '@jarvis/react-portal-addons';
import { InnerSpanHTML } from '../shared-components/InnerHTML';
import {
  Container,
  Line,
  Text,
} from './styles';
import {
  ForestFirstLinkClicked,
  HpRecycleLinkClicked,
  HpSmartLinkClicked,
  LimtedWarrentyLinkClicked,
  MobilePrintingLinkClicked,
  PrintingRequirementLinkClickedEvent,
  publishEvent,
} from '../../utils/analytics';

const AnchorLink = ({ link, onClick }) => (
  <a
    href={`https://${link}`} target="_blank"
    rel="noopener noreferrer" onClick={onClick}
  >
    {link}
  </a>
);

const Legal = ({
  isPrivate,
  instantInkMonths,
  noInstantInk,
}) => {
  const { t } = useI18n();
  const [footnotes, setFootnotes] = useState([]);

  const getAnchorLink = link => {
    const anchorString = `<a href='https://${link}' target="_blank" rel="noopener noreferrer">${link}</a>`;
    return anchorString;
  };

  const allFootnotes = {
    footnote1: () => (
      <InnerSpanHTML>
        {t('home.legalInfo.footnote1')}
      </InnerSpanHTML>
    ),
    footnote2: () => (
      <InnerSpanHTML>
        {t('home.legalInfo.footnote2')}
      </InnerSpanHTML>
    ),
    footnote3: () => (
      <InnerSpanHTML>
        {t('home.legalInfo.footnote3.text1', {
          link: getAnchorLink(t('home.legalInfo.footnote3.link')),
          interpolation: { escapeValue: false },
        })}
      </InnerSpanHTML>
    ),
    footnote4: () => (
      <InnerSpanHTML>
        {
          isPrivate
            ? t((instantInkMonths > 1)
              ? 'home.legalInfo.footnote4.text1'
              : 'home.legalInfo.footnote4.text1_1Month',
            {
              link: getAnchorLink(t('home.legalInfo.footnote4.link')),
              months: instantInkMonths,
              interpolation: { escapeValue: false },
            })
            : t('home.legalInfo.footnote4.text1Generic', {
              link: getAnchorLink(t('home.legalInfo.footnote4.link')),
              interpolation: { escapeValue: false },
            })
        }
      </InnerSpanHTML>
    ),
    footnote5: () => {
      const footnote5Text = t('home.legalInfo.footnote5.text1', { interpolation: { escapeValue: false } });
      const footnote5Split = footnote5Text.split(/{{link}}|{{link2}}/);
      return (
        <span>
          {footnote5Split[0]}
          <AnchorLink
            link={t('home.legalInfo.footnote5.link')}
            onClick={() => publishEvent(PrintingRequirementLinkClickedEvent)}
          />
          {footnote5Split[1]}
          <AnchorLink
            link={t('home.legalInfo.footnote5.link2')}
            onClick={() => publishEvent(HpSmartLinkClicked)}
          />
          {footnote5Split[2]}
        </span>
      );
    },
    footnote6: () => {
      const footnote6Text = t('home.legalInfo.footnote6.text1', { interpolation: { escapeValue: false } });
      const footnote6Split = footnote6Text.split(/{{link}}/);
      return (
        <span>
          {footnote6Split[0]}
          <AnchorLink
            link={t('home.legalInfo.footnote6.link')}
            onClick={() => publishEvent(ForestFirstLinkClicked)}
          />
          {footnote6Split[1]}
        </span>
      );
    },
    footnote7: () => {
      const footnote7Text = t('home.legalInfo.footnote7.text1', {
        tagPlaceHolderStart: '<p>',
        tagPlaceHolderEnd: '</p>',
        interpolation: { escapeValue: false },
      });
      const footnote7Split = footnote7Text.split(/{{link}}/);
      return (
        <span>
          <InnerSpanHTML>
            {footnote7Split[0]}
          </InnerSpanHTML>
          <AnchorLink
            link={t('home.legalInfo.footnote7.link')}
            onClick={() => publishEvent(LimtedWarrentyLinkClicked)}
          />
          <InnerSpanHTML>
            {footnote7Split[1]}
          </InnerSpanHTML>
        </span>
      );
    },
    footnote8: () => {
      const footnote8Text = t('home.legalInfo.footnote8.text1', { interpolation: { escapeValue: false } });
      const footnote8Split = footnote8Text.split(/{{link}}/);
      return (
        <span>
          {footnote8Split[0]}
          <AnchorLink
            link={t('home.legalInfo.footnote8.link')}
            onClick={() => publishEvent(HpRecycleLinkClicked)}
          />
          {footnote8Split[1]}
        </span>
      );
    },
    footnote9: () => {
      const footnote9Text = t('home.legalInfo.footnote9.text1', { interpolation: { escapeValue: false } });
      const footnote9Split = footnote9Text.split(/{{link}}/);
      return (
        <span>
          {footnote9Split[0]}
          <AnchorLink
            link={t('home.legalInfo.footnote9.link')}
            onClick={() => publishEvent(MobilePrintingLinkClicked)}
          />
          {footnote9Split[1]}
        </span>
      );
    },
  };

  useEffect(() => {
    if (noInstantInk) {
      setFootnotes([
        allFootnotes.footnote1(),
        allFootnotes.footnote5(),
        allFootnotes.footnote6(),
        allFootnotes.footnote7(),
        allFootnotes.footnote8(),
        allFootnotes.footnote9(),
      ]);
    } else {
      setFootnotes([
        allFootnotes.footnote1(),
        allFootnotes.footnote2(),
        allFootnotes.footnote3(),
        allFootnotes.footnote4(),
        allFootnotes.footnote5(),
        allFootnotes.footnote6(),
        allFootnotes.footnote7(),
        allFootnotes.footnote8(),
        allFootnotes.footnote9(),
      ]);
    }
  }, [noInstantInk]);

  return (
    <Container>
      <Line />
      <Text>
        {footnotes.map((footnote, index) => (
          <p key={(() => index)()} id={`footnote${index + 1}`}>
            {`${index + 1}. `}
            {footnote}
          </p>
        ))}
      </Text>
    </Container>
  );
};

Legal.propTypes = {
  isPrivate: PropTypes.bool.isRequired,
  instantInkMonths: PropTypes.number.isRequired,
  noInstantInk: PropTypes.bool.isRequired,
};

AnchorLink.propTypes = {
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Legal;
