import { Stack } from '@jarvis/web-stratus-client';

export const getAgenaUrl = stack => {
  const urlByStack = {
    [Stack.dev]: 'https://agena-pie1.services.hpconnectedpie.com',
    [Stack.pie]: 'https://agena-pie1.services.hpconnectedpie.com',
    [Stack.stage]: 'https://agena-stage1.services.hpconnectedstage.com',
    [Stack.prod]: 'https://agena.services.hpconnected.com',
  };
  return urlByStack[stack] || urlByStack[Stack.prod];
};
