import styled from 'styled-components';
import IconInfo from '@veneer/core/dist/scripts/icons/icon_info';
import Tokens from '@veneer/tokens';
import { SuperscriptLink } from '../shared-components/Link/styles';

export const IconInformation = styled(IconInfo)`
  && {
    vertical-align: middle;
  }
`;

export const ContainerDesktop = styled.div`
  display: flex;
  flex-direction: column;
  padding: 38px;
  background-color: #f8fafb;

  @media (max-width: ${props => props.theme.screens.md}) {
    display: none;
  }
`;

export const ContainerMobile = styled.div`
  display: none;
  padding-top: 24px;
  background-color: #f8fafb;

  @media (max-width: ${props => props.theme.screens.md}) {
    display: flex;
    flex-direction: column;
  }
`;

export const Table = styled.div`
  display: grid;
  grid-template-columns: 354px 218px 228px;
  padding-top: 16px;
  padding-left: 60px;
  grid-column-gap: 16px;
  background-color: #f8fafb;
`;

export const TableMobile = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  padding-bottom: 24px;
`;

export const MobileGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  min-height: 56px;
  background: #FFFFFF;

  img {
    justify-self: center;
    align-self: center;
  }
`;

export const MobileGridHeader = styled(MobileGrid)`
  background: #F8F8F8;
`;

export const MobileHeaderText = styled.div`
  color: ${props => props.fontColor};
  font-size: 16px;
  justify-self: center;
  align-self: center;
`;

export const MobileText = styled.div`
  color: ${props => props.fontColor};
  font-size: 16px;
  line-height: 20px;
  justify-self: center;
  align-self: center;
  text-align: center;
  padding: 8px 16px;
`;

export const MobileTextUppercase = styled.div`
  color: #404040;
  background-color: #FFFFFF;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  text-align: center;
  padding-top: 16px;
`;

export const VerticalLine = styled.div`
  height: 24px;
  justify-self: center;
  align-self: center;
  border: 1px solid #DBDBDB;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #E87722;
  width: 100%;
  padding-bottom: 16px;
`;

export const Text1 = styled.div`
  font-family: ${Tokens.fontTextMedium};
  font-size: 32px;
  line-height: 36px;
  color: #D06702;
  padding-bottom: 16px;

  @media (max-width: ${props => props.theme.screens.md}) {
    color: #FFFFFF;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    padding-top: 24px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 8px;
  }
`;

export const Text2 = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #404040;

  @media (max-width: ${props => props.theme.screens.md}) {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    padding: 0;
  }
`;

export const CellTextGrid = styled.div`
  display: grid;
  grid-template-columns: 13px auto 13px;
  padding: 0 8px;

  img {
    justify-self: center;
    align-self: center;
  }
`;

export const CellTitle = styled.div`
  display: flex;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
  height: 47px;
  align-items: center;
`;

export const CellTitleOrange = styled(CellTitle)`
  color: #D06702;
`;

export const CellText = styled.div`
  font-size: 15px;
  color: ${props => props.fontColor};
  min-height: 33px;
  max-height: auto;
  display: flex;
  line-height: 15px;
  align-items: center;
  padding: 2px 8px;
`;

export const CellLabels = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-bottom: 8px;
  padding-right: 20px;
`;

export const OnlyNow = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 0;
  height: auto;
  padding: 5px;
  min-width: 125px;
  max-width: 205px;
  background: #D06702;
  border-radius: 10px 10px 0px 0px;
  font-size: 12px;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
`;

export const CellHpPlus = styled(CellLabels)`
  padding-left: 24px;
  padding-right: 24px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(33, 33, 33, 0.101961);
  border: 1px solid #E2E2E2;
  box-sizing: border-box;
  border-radius: 16px 0 16px 16px;
  background: #FFFFFF;
`;

export const CellWithoutHPPlus = styled(CellHpPlus)`
  border-radius: 16px;
`;

export const Line = styled.div`
  border: 1px solid #E2E2E2;
`;

export const LineMobile = styled(Line)`
  margin: 0 24px;
`;

export const LineBold = styled.div`
  border: 2px solid #E2E2E2;
`;

export const Spacer = styled(LineBold)`
  border-color: transparent;
`;

export const ContainerSubHeaderMobile = styled.div`
  display: flex;
  margin: auto;
`;

export const LinkMobile = styled.a`
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;

  :hover {
    color: #FFFFFF;
  }
`;

export const LinkDesktop = styled.a`
  padding: 11px 0 0 68px;
  font-size: 16px;
  line-height: 20px;
  color: #006691;

  :hover {
    color: #006691;
  }
`;

export const StyledLink = styled(SuperscriptLink)`
`;

export const StyledLinkDesktop = styled(SuperscriptLink)`
`;

export const StyledLinkHeaderMobile = styled(SuperscriptLink)`
  && {
    color: ${Tokens.colorWhite};
    :hover, :active {
      color: ${Tokens.colorWhite};
    }
  }
`;

export const StyledLinkMobile = styled(SuperscriptLink)`
`;
