export const PATHS = {
  MY_PRINTERS: '/my-printers',
  HOME: '/',
};

export const COUNTRIES = {
  US: 'us',
  AUS: 'au',
  NZ: 'nz',
};
